import { SearchBarMenuOptions } from '@/components/molecules/SearchBarMenuOptions/SearchBarMenuOptions';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo } from 'react';
import { stringMatch } from '../../../lib/searchAlgorithms';
import {
  DropdownMenuGroupType,
  DropdownMenuOptionType,
  DropdownMenuProps,
} from '../DropdownMenu/DropdownMenu';
import { SearchBar, SearchBarProps } from '../SearchBar/SearchBar';

interface SearchBarWithMenuProps
  extends Omit<SearchBarProps, 'value' | 'onChange'>,
    Omit<DropdownMenuProps, 'value' | 'onChange' | 'options'> {
  currentVectorSearch?: string;
  handleVectorSearch?: (input: string) => void;
  limitSuggestions?: boolean;
  setInput: (input: string) => void;
  input: string;
  showOptions: boolean;
  setShowOptions: (open: boolean) => void;
  searchBarVariant?: 'dialog' | 'section';
}

export const SearchBarWithMenu = ({
  input = '',
  setInput,
  models = {},
  makes = [],
  placeholder,
  currentVectorSearch,
  handleVectorSearch = () => {},
  limitSuggestions = true,
  setShowOptions,
  showOptions,
  searchBarVariant = 'section',
}: SearchBarWithMenuProps) => {
  useEffect(() => {
    if (currentVectorSearch === '') {
      setInput('');
    }
  }, [currentVectorSearch, setInput]);

  const dropdownOptions: DropdownMenuOptionType[] | DropdownMenuGroupType[] =
    useMemo(() => {
      if (!input) {
        return [];
      }

      const matchedItems = stringMatch(input.toLowerCase(), makes, models);
      return limitSuggestions ? matchedItems.slice(0, 5) : matchedItems;
    }, [input, makes, models, limitSuggestions]);

  const handleDropdownSelect = useCallback(
    (selected: string) => {
      setInput(selected);
      handleVectorSearch(selected);
    },
    [setInput, handleVectorSearch]
  );

  const handleEnter = useCallback(
    (val: string | undefined) => {
      if (val && input !== currentVectorSearch && showOptions) {
        handleVectorSearch(input);
      }
      setShowOptions(false);
    },
    [
      currentVectorSearch,
      handleVectorSearch,
      input,
      showOptions,
      setShowOptions,
    ]
  );

  return (
    <div
      className={clsx('flex h-full w-full flex-col', {
        'px-l pt-m': searchBarVariant === 'dialog',
      })}
    >
      <div className="flex items-center gap-s">
        {searchBarVariant === 'dialog' && (
          <div className="cursor-pointer">
            <ChevronLeftIcon
              className="size-xl"
              onMouseDown={() => setShowOptions(false)}
            />
          </div>
        )}
        <SearchBar
          value={input}
          onChange={setInput}
          placeholder={placeholder}
          onEnter={handleEnter}
          onFocus={() => setShowOptions(true)}
          onBlur={() => handleEnter(input)}
        />
      </div>
      {showOptions && (
        <SearchBarMenuOptions
          autoCompleteSuggestions={dropdownOptions}
          onChange={(val: string[]) => {
            handleDropdownSelect(val[0]);
          }}
          searchBarVariant={searchBarVariant}
        />
      )}
    </div>
  );
};

import { MakeIcon } from '@/components/atoms/MakeIcons/MakeIcon';
import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import DropdownMenuItem, {
  DropdownMenuItemVariants,
} from '@/components/molecules/DropdownMenuItem/DropdownMenuItem';
import { RecentSearchList } from '@/components/organisms/RecentSearchList';
import useWindowSize from '@/hooks/useWindowSize';
import { POPULAR_SEARCHES, POPULAR_SEARCH_MAKES } from '@/lib/constants';
import { BodyTypeOption } from '@/lib/schema/inventory/types';
import clsx from 'clsx';
import { useState } from 'react';
import DropdownMenu, {
  DropdownMenuGroupType,
  DropdownMenuOptionType,
  DropdownMenuProps,
} from '../DropdownMenu/DropdownMenu';

const SEARCH_BODY_TYPE_OPTIONS: BodyTypeOption[] = [
  {
    icon: {
      iconId: IconIds.Suv,
      className: 'h-[24px] w-[60px]',
    },
    value: 'SUV',
  },
  {
    icon: {
      iconId: IconIds.Sedan,
      className: 'h-[24px] w-[60px]',
    },
    value: 'Sedan',
  },
  {
    icon: {
      iconId: IconIds.Coupe,
      className: 'h-[24px] w-[60px]',
    },
    value: 'Coupe',
  },
  {
    icon: {
      iconId: IconIds.Truck,
      className: 'h-[24px] w-[60px]',
    },
    value: 'Truck',
  },
];

interface SearchBarMenuOptionsProps
  extends Omit<DropdownMenuProps, 'value' | 'options'> {
  searchBarVariant: 'dialog' | 'section';
  autoCompleteSuggestions: DropdownMenuOptionType[] | DropdownMenuGroupType[];
}

export const SearchBarMenuOptions = ({
  autoCompleteSuggestions,
  onChange,
  searchBarVariant,
}: SearchBarMenuOptionsProps) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const { isML, isDesktop } = useWindowSize();
  const popularMakes =
    !isML && !isDesktop
      ? POPULAR_SEARCH_MAKES.slice(0, 4)
      : POPULAR_SEARCH_MAKES;

  return (
    <div className="z-20 h-full w-full">
      {autoCompleteSuggestions.length !== 0 ? (
        <DropdownMenu
          options={autoCompleteSuggestions}
          value={[]}
          onChange={onChange}
          variant={DropdownMenuItemVariants.NoSelect}
          className={
            searchBarVariant === 'dialog'
              ? 'max-h-none bg-neutral-100 shadow-none'
              : undefined
          }
        />
      ) : (
        <div
          className={clsx(
            'flex flex-col gap-l overflow-x-hidden rounded-small',
            {
              'h-[85%] overflow-y-auto bg-neutral-100 shadow-none':
                searchBarVariant === 'dialog',
              'h-content bg-neutral-0 px-m pb-l pt-s shadow-md':
                searchBarVariant === 'section',
            }
          )}
        >
          <RecentSearchList onChange={onChange} />
          <div className="flex flex-col-reverse gap-l pb-safe-bottom ml:flex-col">
            <div className="flex flex-col gap-s">
              <span className="block pt-s text-microMedium text-brandMidnight">
                Popular Searches
              </span>
              {POPULAR_SEARCHES.map((searchOption, index) => {
                return (
                  <div
                    key={`dropdown-${searchOption.make}-${searchOption.model}-${index}`}
                    className={clsx(
                      'h-full border-solid border-neutral-200 ml:border-b-0',
                      {
                        'border-b-0': index === POPULAR_SEARCHES.length - 1,
                        'border-b': index !== POPULAR_SEARCHES.length - 1,
                      }
                    )}
                  >
                    <DropdownMenuItem
                      text={searchOption.value || ''}
                      variant={DropdownMenuItemVariants.NoSelect}
                      showCount
                      onChange={() => {
                        onChange([searchOption.value]);
                      }}
                      selected={false}
                    />
                  </div>
                );
              })}
              <div className="hidden h-full border-[1px] border-solid border-neutral-200 ml:block"></div>
            </div>

            <div className="flex flex-col gap-s ml:hidden">
              <span className="block pt-s text-microMedium text-brandMidnight">
                Search by body type
              </span>
              <div className="grid grid-cols-4 gap-s">
                {SEARCH_BODY_TYPE_OPTIONS.map((option, index) => {
                  return (
                    <div
                      onMouseDown={() => onChange([option.value])}
                      key={`${option.icon.iconId}-${index}`}
                      aria-label={`filter by ${option.value}`}
                      className={clsx(
                        'flex cursor-pointer flex-col items-center justify-center gap-s rounded-small border p-l hover:border-brandElectric hover:bg-green-light hover:text-brandMidnight',
                        {
                          'border-neutral-100 bg-neutral-100':
                            searchBarVariant === 'section',
                          'border-neutral-0 bg-neutral-0':
                            searchBarVariant === 'dialog',
                        }
                      )}
                      onTouchStart={() => setHoveredIndex(index)}
                      onTouchEnd={() => setHoveredIndex(null)}
                    >
                      <Icon
                        iconId={
                          hoveredIndex === index
                            ? (`${option.icon.iconId}Solid` as IconIds)
                            : option.icon.iconId
                        }
                        className={option.icon.className}
                      />
                      <div className="text-body1Light text-neutral-800">
                        {option.value}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-col gap-s">
              <span className="block pt-s text-microMedium text-brandMidnight">
                Search by popular makes
              </span>
              <div className="flex justify-around">
                {popularMakes.map((popularMake, index) => {
                  return (
                    <div
                      key={`popular-makes-${popularMake.make}-${index}`}
                      onMouseDown={() => onChange([popularMake.value])}
                      aria-label={`filter by ${popularMake.value}`}
                      className="flex cursor-pointer flex-col justify-center gap-s rounded-full bg-neutral-0 p-m ml:bg-neutral-100 ml:p-0"
                    >
                      <MakeIcon
                        className="relative z-10 flex aspect-square items-center justify-center rounded-full bg-neutral-100 p-l shadow-s hover:shadow-l"
                        width={32}
                        height={32}
                        make={popularMake.make}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
